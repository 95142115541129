import React from 'react'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Content from '../components/ui/content'
import PageHeader from '../components/ui/page-header'
import { StaticImage } from 'gatsby-plugin-image'

const Contact = () => {
  return (
    <Layout menuLang='en'>
      <Seo title='Contact' article lang='en' />
      <PageHeader title='Contact' />

      <Container className='flex flex-wrap py-20'>
        <div className='w-full md:w-1/2'>
          <p className='text-sm'>
            <b>Office:</b>

            <p>Saraylar Mah. 367 Sok. No: 17 20010 Denizli / Turkey</p>

            <p>Phone: +90 258 263 05 60</p>
            <p>Fax: +90 258 263 59 73</p>
            <p>Email: sayintextile@sayintextile.com </p>
          </p>
          <p className='text-sm my-4'>
            <b>Factory:</b>
            <p>Guzelkoy Mah. 247 Sok. No: 29/A Denizli / Turkey</p>
            <p>Phone: +90 258 267 15 01</p>
            <p>Fax: +90 258 267 15 01</p>
          </p>
        </div>
        <div className='w-full md:w-1/2'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.1888822146925!2d29.079086715129172!3d37.785613019309245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73fb75089ec65%3A0x911b8e1350d69294!2sSay%C4%B1n%20Tekstil%20Sanayi%20ve%20Ticaret%20Limited%20%C5%9Eirketi!5e0!3m2!1str!2str!4v1607347332686!5m2!1str!2str'
            width='600'
            height='450'
            frameborder='0'
            style={{ border: '0' }}
            allowfullscreen=''
            aria-hidden='false'
            tabindex='0'
          ></iframe>
        </div>
      </Container>
    </Layout>
  )
}

export default Contact
